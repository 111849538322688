.navbar {
  position: fixed;
  top: 0;
  z-index: 9;
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--color-border);
  box-sizing: border-box;
  background-color: var(--color-bg-2);
  height: var(--navbar-height);
}

.left {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.logo {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  height: 100%;
  min-width: 80px;
}

.logo-text {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  height: 100%;
  min-width: 250px;
  .logo-span {
    font-family: BAHNSCHRIFT;
    font-size: x-large;
    width: 100%;
    text-align: center;
    color: rgb(0, 28, 53);
    letter-spacing: 4px;
  }
}

.menu {
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

.mobile-menu {
  position: fixed;
  left: 0;
  top: 64px;
  width: 200px;
}

.right {
  display: flex;
  padding: 0;
  min-width: 128px;
  justify-content: center;
  align-items: center;
}

.btn-container {
  margin: 0 8px;
}

@arcoblue-6: #63C8DC;