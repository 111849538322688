.footer {
  margin-top: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  text-align: center;
  color: #eff0f7;
  background-color: #272933;
}

.logo {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  height: 100%;
  min-width: 80px;
}

@arcoblue-6: #63C8DC;